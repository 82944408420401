import { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import Innernavbar from "../Components/Innernavbar";
import apiService from "../apiService";
import { Link } from "react-router-dom";
import {Helmet} from 'react-helmet';
import Navbar from '../Components/Navbar';


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Services(){

  const [get_services,set_get_servies] = useState([]);
  const [get_popular_services, set_get_popular_services] = useState([]);
  const [get_all_services, set_get_all_services] = useState([]);
  const [new_search_qu, set_new_search_qu] = useState([]);
  const [filtered, setfiltered] = useState(0);
  const [fil_cat, setfil_cat] = useState([])

  const [getfil_cat, setgetfil_cat] = useState([])

useEffect(()=>{
get_services_data();
category_within_category();
},[])



const category_within_category = async()=>{
const cat_with_cat = await apiService.get_cat_with_cat()
setfil_cat(cat_with_cat.data)
}


const options_top_picks = {
  loop: true,
  margin: 20,
  autoplay: true,
  autoplayTimeout: 3000,
  autoplayHoverPause: true,
  responsive: {
    0: { items: 1.4},
    600: { items: 2.5 },
    1000: { items: 4.5},
  },
};

const options_3_5 = {
  loop: true,
  margin: 20,
  autoplay: true,
  autoplayTimeout: 3000,
  autoplayHoverPause: true,
  responsive: {
    0: { items: 1.4},
    600: { items: 2.5 },
    1000: { items: 3.5},
  },
}

const options_travel = {
  loop: true,
  margin: 20,
  autoplay: true,
  autoplayTimeout: 3000,
  autoplayHoverPause: true,
  responsive: {
    0: { items: 1.4},
    600: { items: 2.5 },
    1000: { items: 2.5},
  },
};


const get_search_data = (e)=>{
  
  var le = e.target.value;
  var length = le.toString();
  if(length.length==0){
    setfiltered(0)
    get_services_data();
  }else{
   // console.log(new_search_qu);
    const n_ary = new_search_qu.map((obj) => {
      if (obj[Object.keys(obj)[0]].length > 0) {
        obj["new_tmp_query"] = (Object.keys(obj)[0] + " " + obj[Object.keys(obj)[0]].map((ol) => ol.name).join(', ')).toLowerCase();
      } else {
        obj["new_tmp_query"] = ""; // Set empty string if array is empty
      }
      return obj;
    });

    const filteredData = n_ary.filter(obj => obj.new_tmp_query.toLowerCase().includes(le));
    set_get_all_services(filteredData);
   // console.log(filteredData);
    setfiltered(1)
  }
}

const get_services_data = async()=>{
  const serv = await apiService.get_services_data();
  if(serv.status==200){
   // console.log(serv.data.data);
    set_get_servies(serv.data.data);
    
    set_get_popular_services(serv.data.data.popular_services)
    set_get_all_services(serv.data.data.all_services)
    set_new_search_qu(serv.data.data.all_services);
  }
}

const search_get_cat = (e)=>{
    const filtered = fil_cat.filter((category) =>
        category.name.toLowerCase().includes(e.toLowerCase())
      );
      setgetfil_cat(filtered)

      //console.log(filtered)
}

return(
    <>
            <Helmet>
        <link rel="stylesheet" href="/assets/css/service.css" />
      </Helmet>
<Navbar />


        <section class="bg-white bg-center h-screen relative">
            <div class="flex flex-col md:gap-3 text-center items-center justify-center h-full max-w-screen-xl mx-auto px-4">
                <h1 class="text-[#1A121A] text-4xl md:text-6xl font-bold mb-4 md:w-3/5 lg:w-4/6">Trusted, On-Demand Services at Your Fingertips!</h1>
                <p class="text-[#1A121A] text-lg md:text-xl md:w-3/5 lg:w-4/6 mb-8 font-semibold">At Reliep, easily access essential services like home repairs, fitness, and healthcare. From booking a handyman to scheduling appointments, we’ve got you covered.</p>
                
                <div class="flex flex-row items-center w-full md:w-3/4 lg:w-3/5 mb-4 bg-white rounded-lg shadow-xl overflow-hidden">
                
                    <input type="text" placeholder="Search your service" class="flex-grow text-gray-800 pl-3 py-2 focus:outline-none" onKeyUp={(e)=>{search_get_cat(e.target.value)}} />
                
                    <img src="/services-img/images/map-pin.png" alt="Location Icon" class="w-6 h-6 mx-2" />
               
                    {/* <select class="bg-white text-gray-800 pr-3 py-2 focus:outline-none">
                        <option value="">Select Location</option>
                        <option value="location1">Location 1</option>
                        <option value="location2">Location 2</option>
                        <option value="location3">Location 3</option>
                    </select> */}

{/* 
<div>
                    {getfil_cat.map((data,key)=>{
                        return(
                            <>
                            <div>{data.name}</div>
                            </>
                        )
                    })}
                    
                 </div> */}

                    <div class="p-1">
                        <button class="bg-[#850C85] rounded-md text-white px-4 py-2 hover:bg-purple-700 transition hidden md:block">Search</button>
                    </div>
                </div>
    
                <div class="w-full md:hidden mb-4">
                    <button class="bg-[#850C85] font-semibold rounded-md text-white px-6 py-2 hover:bg-purple-700 transition w-full">Search</button>
                </div>
        
                <div class="flex items-center mt-4">
                    <img src="/services-img/images/customer-satisfaction-badge.png" alt="Badge" class="w-10 h-10 mr-4" />
                    {/* <span class="text-[#1A121A] text-lg font-semibold">100% Customer Satisfaction</span> */}
                    <span class="text-[#1A121A] text-lg font-semibold">Service Starts from &#8377;49 Only</span>
                </div>
            </div>
        
         
             <div class="hidden lg:block">
                <div class="absolute top-16 left-36">
                    <img src="/services-img/images/small-image-1.png" alt="Small Image 1" class="w-16 h-16" />
                </div>
                <div class="absolute top-16 right-48">
                    <img src="/services-img/images/small-image-2.png" alt="Small Image 2" class="w-16 h-16" />
                </div>
                <div class="absolute bottom-40 left-28">
                    <img src="/services-img/images/small-image-3.png" alt="Small Image 3" class="w-16 h-16" />
                </div>
                <div class="absolute bottom-36 right-36">
                    <img src="/services-img/images/small-image-4.png" alt="Small Image 4" class="w-16 h-16" />
                </div>
             </div>


        </section>
        
        
        
          



        <section class="py-20 bg-white">
            <div class="max-w-screen-xl mx-auto px-4">
                {/* <h2 class="text-center text-3xl md:text-4xl font-bold mb-12">Featured Services</h2> */}
                <h2 class="text-center text-3xl md:text-4xl font-bold mb-12">Service</h2>
                <div class="grid grid-cols-2 gap-4 md:grid-cols-4">
        
                    {/* <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/book-a-ride.png')"}}>
                        <div class="absolute bottom-0 left-0 right-0  bg-opacity-50 p-4">
                            <span class="text-white text-xl font-semibold">Book a Ride</span>
                        </div>
                    </div> */}
        
            
                    {/* <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/bills-&-recharge.png')"}}>
                        <div class="absolute bottom-0 left-0 right-0  bg-opacity-50 p-4">
                            <span class="text-white text-xl font-semibold">Bills & Recharge</span>
                        </div>
                    </div> */}
        
                  {/* <a href="/categories/fashion">
                    <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/fashion.png')"}}>
                        <div class="absolute bottom-0 left-0 right-0  bg-opacity-50 p-4">
                            <span class="text-white text-xl font-semibold">Fashion</span><br></br>
                            <span class="text-white text-xl font-normal">Starts &#8377;99</span>
                        </div>
                    </div>
                    </a> */}
        
                    <a href="/categories/repairs-service">
                    <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/repair-&-service.png')"}}>
                        <div class="absolute bottom-0 left-0 right-0  bg-opacity-50 p-4">
                            <span class="text-white text-xl font-semibold">Repair & Service</span>
                            <br></br>
                            <span class="text-white text-xl font-normal">Starts &#8377;49</span>
                        </div>
                    </div>
                    </a>


                </div>
            </div>
        </section>



        {/* <section class="py-20 ">
            <div class="max-w-screen-xl mx-auto px-4">
                <h2 class="text-center text-3xl md:text-4xl  font-bold mb-6">Top Picks Just for You!</h2>
                <p class="text-center text-sm md:text-md  mb-12 ">Discover our most popular services, handpicked to meet your needs with quality and convenience.</p>
               
               
                <OwlCarousel class="owl-carousel owl-theme top-picks" {...options_top_picks}>
             
                    <div class="item">
                        <a href="/categories/repairs-service">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/repair&service.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Repair & Service</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;49</span>
                            </div>
                        </div>
                        </a>
                    </div>
              
                    <div class="item">
                    <a href="/categories/hotel">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/hotel.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Hotel</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;149</span>
                            </div>
                        </div>
                        </a>
                    </div>
           
                    <div class="item">
                    <a href="/categories/consultant">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/consultation.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Consultant</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;199</span>
                            </div>
                        </div>
                        </a>
                    </div>
            
                    <div class="item">
                    <a href="/categories/food-order">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/grocerries.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Grocery</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;149</span>
                            </div>
                        </div>
                        </a>
                    </div>
              
                    <div class="item">
                    <a href="/categories/jobs">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/job.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Job</span>
                            </div>
                        </div>
                        </a>
                    </div>
            
                    <div class="item">
                    <a href="/categories/jobs">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/training&skillup.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Training & Skillup</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;249</span>
                            </div>
                        </div>
                        </a>
                    </div>
          
                   
                  
                </OwlCarousel>



            </div>
        </section> */}


        <div class="flex items-center justify-center  max-w-screen-xl mx-auto px-4">
            <img src="/services-img/images/gift-card.png" alt="Description of the image" class="max-w-full h-auto rounded-lg shadow-md" />
        </div>





        {/* <section class="py-20 ">
            <div class="max-w-screen-xl mx-auto px-4">
                <h2 class="text-center text-3xl md:text-4xl font-bold mb-6">Reliable Transportation Services</h2>
                <p class="text-center   mb-12">Get fast, safe, and convenient transportation solutions tailored to your needs, anytime you need them.</p>
                
                
                <OwlCarousel class="owl-carousel owl-theme transportation-carousel" {...options_top_picks}>
             <a href="/categories/driver">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/driver.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Driver</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;249</span>
                            </div>
                        </div>
                    </div>
                    </a>
               
                   
                    <a href="/categories/ambulance">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/ambulance.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Ambulance</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;449</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/transport">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/transpotation.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Lorry</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;449</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/loaders">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/loading.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Loading</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;1049</span>
                            </div>
                        </div>
                    </div>
                    </a>
             

                </OwlCarousel>



            </div>
        </section>


    
        <section class="py-20 ">
            <div class="max-w-screen-xl mx-auto px-4">
                <div class="flex flex-col md:flex-row md:gap-8">
            
                    <div class="md:w-2/5 mb-8 md:mb-0 flex items-center">
                        <div>
                            <h2 class="text-3xl font-bold mb-4">Travel And Booking</h2>
                            <p class="text-gray-700">A trusted professional arrives to complete the job, leaving you with peace of mind.A trusted professional arrives to complete the job, leaving you with peace of mind.</p>
                        </div>
                    </div>
        
              
                    <div class="md:w-3/5 flex items-center">
                        <OwlCarousel class="owl-carousel owl-theme travel-carousel" {...options_travel}>
                
                            <div class="item w-full ">
                                <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/bus-booking.png')"}}>
                                    <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                        <span class="text-white text-xl font-semibold">Bus Booking</span><br></br>
                                        <span class="text-white text-xl font-normal">Starts &#8377;99</span>
                                    </div>
                                </div>
                            </div>
        
         
                           
        
            
                            <div class="item w-full ">
                                <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/hotel-booking.png')"}}>
                                    <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                        <span class="text-white text-xl font-semibold">Hotel Booking</span><br></br>
                                        <span class="text-white text-xl font-normal">Starts &#8377;449</span>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>



                    </div>
                </div>
            </div>
        </section>        


        <section class="py-20 ">
            <div class="max-w-screen-xl mx-auto px-4">
                <h2 class="text-center text-3xl md:text-4xl font-bold mb-6">Buy and Sell with Ease!</h2>
                <p class="text-center   mb-12">Discover the latest trends and shop your favorite styles effortlessly.</p>
                <OwlCarousel class="owl-carousel owl-theme buy-sell-carousel" {...options_top_picks}>

                <a href="/categories/mobile">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/mobile&laptop.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Mobiles & Laptops</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;249</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/bike">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/bike.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Bike</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;49</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/car">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/car.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Car</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;49</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/furniture">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/furniture.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Furniture</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;449</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/book">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/book.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Books</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;149</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/pet">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/pets.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Pets</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;149</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/property">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/property.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Property</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;1049</span>
                            </div>
                        </div>
                    </div>
                    </a>
                </OwlCarousel>
            </div>
        </section>




        <section class="py-20 bg-cover" style={{"backgroundImage": "url('/services-img/images/fashion-bg.png')"}}>
            <div class="max-w-screen-xl mx-auto px-4">
                <h2 class="text-center text-3xl md:text-4xl font-bold mb-6 text-black">Stylish Fashion at Your Fingertips!</h2>
                <p class="text-center text-sm md:text-md mb-12 text-black">Shop the latest fashion trends with ease, delivered right to your door.</p>
               
               
                <OwlCarousel class="owl-carousel owl-theme fashion-picks" {...options_3_5}>
                <a href="/categories/men">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-[500px] w-full relative" style={{"backgroundImage": "url('/services-img/images/men-fashion.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4 md:p-8">
                                <span class="text-white text-xl font-semibold">Men</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;149</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/women">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-[500px] w-full relative" style={{"backgroundImage": "url('/services-img/images/women-fashion.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4 md:p-8">
                                <span class="text-white text-xl font-semibold">Women</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;149</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/kids">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-[500px] w-full relative" style={{"backgroundImage": "url('/services-img/images/kids-fashion.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4 md:p-8">
                                <span class="text-white text-xl font-semibold">Kids</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;149</span>
                            </div>
                        </div>
                    </div>
                    </a>
        
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-[500px] w-full relative" style={{"backgroundImage": "url('/services-img/images/deal-fashion.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4 md:p-8">
                                <span class="text-white text-xl font-semibold">Hot Deals</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;99</span>
                            </div>
                        </div>
                    </div>
               

                </OwlCarousel>
            </div>
        </section> */}
        
        


        {/* <section class="py-20 ">
            <div class="max-w-screen-xl mx-auto px-4">
                <div class="flex flex-col md:flex-row md:gap-8">
          
                    <div class="md:w-2/5 mb-8 md:mb-0 flex items-center">
                        <div>
                            <h2 class="text-3xl font-bold mb-4">Your Health, Our Priority!</h2>
                            <p class="text-gray-700">Get expert healthcare services at your convenience, from consultations to wellness care, right at your doorstep.</p>
                        </div>
                    </div>
        
               
                    <div class="md:w-3/5 flex items-center">


                        <OwlCarousel class="owl-carousel owl-theme health-carousel" {...options_travel}>
                        <a href="/categories/gym">
                            <div class="item w-full ">
                                <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/gym.png')"}}>
                                    <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                        <span class="text-white text-xl font-semibold">Gym</span><br></br>
                                        <span class="text-white text-xl font-normal">Starts &#8377;149</span>
                                    </div>
                                </div>
                            </div>
                            </a>
        
                            <a href="/categories/yoga">
                            <div class="item w-full ">
                                <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/yoga.png')"}}>
                                    <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                        <span class="text-white text-xl font-semibold">Yoga</span><br></br>
                                        <span class="text-white text-xl font-normal">Starts &#8377;149</span>
                                    </div>
                                </div>
                            </div>
                            </a>
        
                            <a href="/categories/karate">
                            <div class="item w-full ">
                                <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/karate.png')"}}>
                                    <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                        <span class="text-white text-xl font-semibold">Karate</span><br></br>
                                        <span class="text-white text-xl font-normal">Starts &#8377;49</span>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </OwlCarousel>




                    </div>
                </div>
            </div>
        </section>


        <section class="py-20 ">
            <div class="max-w-screen-xl mx-auto px-4">
                <h2 class="text-center text-3xl md:text-4xl font-bold mb-6">Expert Home Services</h2>
                <p class="text-center   mb-12">From repairs to cleaning, our trusted professionals handle all your home needs quickly and reliably.</p>
                
                
                <OwlCarousel class="owl-carousel owl-theme home-service" {...options_top_picks}>
                <a href="/categories/plumbing">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/plumber.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Plumber</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;149</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/electrician">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/electrician.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Electrician</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;149</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/painter">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/painter.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Painter</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;249</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/car-mechanic">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/mechanic.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Mechanic</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;249</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/cleanerhousekeeper">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/lab-assistant.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Cleaner</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;249</span>
                            </div>
                        </div>
                    </div>
                    </a>
                  
                   
                </OwlCarousel>


            </div>
        </section>       



        <section class="py-20 bg-cover" style={{"backgroundImage": "url('/services-img/images/pamper-bg.png')"}}>
            <div class="max-w-screen-xl mx-auto px-4">
                <h2 class="text-center text-3xl md:text-4xl font-bold mb-6 text-black">Pamper Yourself with Expert Beauty Services!</h2>
                <p class="text-center text-sm md:text-md mb-12 text-black">Get professional beauty and care treatments at home, tailored to make you look and feel your best.</p>
               
               
               
                <OwlCarousel class="owl-carousel owl-theme pamper-yourself" {... options_3_5}>
                <a href="/categories/manicure">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-[500px] w-full relative" style={{"backgroundImage": "url('/services-img/images/pedicure.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4 md:p-8">
                                <span class="text-white text-xl font-semibold">Manicure</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;249</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/pedicure">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-[500px] w-full relative" style={{"backgroundImage": "url('/services-img/images/manicure.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4 md:p-8">
                                <span class="text-white text-xl font-semibold">Pedicure</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;249</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/hair-care">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-[500px] w-full relative" style={{"backgroundImage": "url('/services-img/images/hair-care.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4 md:p-8">
                                <span class="text-white text-xl font-semibold">Hair Care</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;249</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/facial">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-[500px] w-full relative" style={{"backgroundImage": "url('/services-img/images/facial.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4 md:p-8">
                                <span class="text-white text-xl font-semibold">Facial</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;249</span>
                            </div>
                        </div>
                    </div>
                    </a>

                </OwlCarousel>


            </div>
        </section>
        
        
  
        <section class="py-20 ">
            <div class="max-w-screen-xl mx-auto px-4">
                <h2 class="text-center text-3xl md:text-4xl font-bold mb-6">Unlock Your Potential with Expert Training!</h2>
                <p class="text-center   mb-12">Gain new skills and advance your career with professional courses and personalized learning experiences.</p>
                <OwlCarousel class="owl-carousel owl-theme expert-training" {...options_top_picks}>
                <a href="/categories/tution">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/tution.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Tution</span>
                            </div>
                        </div>
                    </div>
               </a>
               <a href="/categories/music">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/music.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Music</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;249</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/dance">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/dance.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Dance</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;249</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/computer-tech">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/computer&tech.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Computer & Tech</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;249</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/art">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/art.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Art</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;249</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/gromming">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/grooming.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Gromming</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;249</span>
                            </div>
                        </div>
                    </div>
                    </a>
                    <a href="/categories/communication">
                    <div class="item">
                        <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/communication.png')"}}>
                            <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                <span class="text-white text-xl font-semibold">Communication</span><br></br>
                                <span class="text-white text-xl font-normal">Starts &#8377;249</span>
                            </div>
                        </div>
                    </div>
                    </a>
                </OwlCarousel>
            </div>
        </section>  



   
        <section class="py-20 ">
            <div class="max-w-screen-xl mx-auto px-4">
                <div class="flex flex-col md:flex-row md:gap-8">
               
                    <div class="md:w-2/5 mb-8 md:mb-0 flex items-center">
                        <div>
                            <h2 class="text-3xl font-bold mb-4">Legal Expertise at Your 
                                Service!</h2>
                            <p class="text-gray-700">Access trusted legal advice and solutions for all your personal or business needs with ease.</p>
                        </div>
                    </div>
        
          
                    <div class="md:w-3/5 flex items-center">
                        <OwlCarousel class="owl-carousel owl-theme legal-carousel" {...options_travel}>
                        <a href="/categories/law">
                            <div class="item w-full">
                                <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/corporate-law.png')"}}>
                                    <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                        <span class="text-white text-xl font-semibold">Corporate Law</span><br></br>
                                        <span class="text-white text-xl font-normal">Starts &#8377;449</span>
                                    </div>
                                </div>
                            </div>
                            </a>
        
                            <a href="/categories/law">
                            <div class="item w-full ">
                                <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/litigation.png')"}}>
                                    <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                        <span class="text-white text-xl font-semibold">Litigation</span><br></br>
                                        <span class="text-white text-xl font-normal">Starts &#8377;449</span>
                                    </div>
                                </div>
                            </div>
                            </a>
        
                            <a href="/categories/law">
                            <div class="item w-full ">
                                <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/intellectual.png')"}}>
                                    <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                        <span class="text-white text-xl font-semibold">Legal Representation</span><br></br>
                                        <span class="text-white text-xl font-normal">Starts &#8377;449</span>
                                    </div>
                                </div>
                            </div>
                            </a>
                            <a href="/categories/law">
                            <div class="item w-full ">
                                <div class="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/order.png')"}}>
                                    <div class="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                        <span class="text-white text-xl font-semibold">Court Order</span><br></br>
                                        <span class="text-white text-xl font-normal">Starts &#8377;449</span>
                                    </div>
                                </div>
                            </div>
                            </a>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section> */}
        
        


    {/* <section class="py-20  bg-cover " style={{"backgroundImage": "url('/services-img/images/snap-bg.png')"}}>

        <div class="max-w-screen-xl mx-auto px-4">
            <h2 class="text-3xl font-bold mb-8 text-center">Snap</h2>
            <div class="owl-carousel owl-theme snap-carousel">
        
                <div class="item">
                    <div class="bg-black text-white rounded-xl p-6 flex flex-col">
                        <video src="https://www.w3schools.com/html/mov_bbb.mp4" controls class="w-full h-72 rounded-lg mb-4"></video>
                        <h3 class="font-semibold">10k views</h3>
                    </div>
                </div>
      
                <div class="item">
                    <div class="bg-black text-white rounded-xl p-6 flex flex-col">
                        <video src="https://www.w3schools.com/html/mov_bbb.mp4" controls class="w-full h-72 rounded-lg mb-4"></video>
                        <h3 class="font-semibold">10k views</h3>
                    </div>
                </div>
             
                <div class="item">
                    <div class="bg-black text-white rounded-xl p-6 flex flex-col">
                        <video src="https://www.w3schools.com/html/mov_bbb.mp4" controls class="w-full h-72 rounded-lg mb-4"></video>
                        <h3 class="font-semibold">10k views</h3>
                    </div>
                </div>
           
                <div class="item">
                    <div class="bg-black text-white rounded-xl p-6 flex flex-col">
                        <video src="https://www.w3schools.com/html/mov_bbb.mp4" controls class="w-full h-72 rounded-lg mb-4"></video>
                        <h3 class="font-semibold">10k views</h3>
                    </div>
                </div>
  
                <div class="item">
                    <div class="bg-black text-white rounded-xl p-6 flex flex-col">
                        <video src="https://www.w3schools.com/html/mov_bbb.mp4" controls class="w-full h-72 rounded-lg mb-4"></video>
                        <h3 class="font-semibold">10k views</h3>
                    </div>
                </div>
          
                <div class="item">
                    <div class="bg-black text-white rounded-xl p-6 flex flex-col">
                        <video src="https://www.w3schools.com/html/mov_bbb.mp4" controls class="w-full h-72 rounded-lg mb-4"></video>
                        <h3 class="font-semibold">10k views</h3>
                    </div>
                </div>                

            </div>
        </div>
    </section> */}

<Footer />
    </>
)
}

export default Services;