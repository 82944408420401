import React, { useEffect, useState } from 'react';
// import ReactOwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import apiService from '../apiService';
import {Helmet} from 'react-helmet';

// import './home.css';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import RefundPolicy from './refund-policy';

function Home() {

    const [categories, setcategories] = useState([]);

    useEffect(()=>{
   get_categories()
    },[])

    const get_categories = async()=>{
        const data = await apiService.getcategories()
        setcategories(data.data.data);
        
    }

    const options = {
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsive: {
          0: { items: 1, stagePadding: 50 },
          768: { items: 2, stagePadding: 50 },
          1024: { items: 2, stagePadding: 50 },
        },
      };

    return (
        <>
         <Helmet>
        <link rel="stylesheet" href="/assets/css/home.css" />
      </Helmet>
      <Navbar />

    <section className="bg-cover bg-center h-screen" style={{"backgroundImage": "url('/assets/images/hero-image.png')"}}>
        <div className="flex flex-col md:gap-3 text-center md:text-left items-start justify-center h-full max-w-screen-xl mx-auto px-4  ">
            <h1 className="text-[#1A121A] text-4xl md:text-6xl font-bold mb-4 md:w-3/4 lg:w-3/5">Your One-Stop Solution for Everyday Services</h1>
            <p className="text-[#1A121A] text-lg md:text-xl md:w-3/4 lg:w-3/5 mb-8">We connect you with trusted professionals to handle your tasks quickly and affordably. Whether it's a small fix or a larger job, booking is simple, and help is just a click away.</p>
            
            <div className="flex flex-row items-center w-full md:w-3/4 lg:w-3/5 mb-4 bg-white rounded-lg shadow-md overflow-hidden">
                
              

                
                
                <input type="text" placeholder="Search your service" className="flex-grow text-gray-800 pl-3 py-2 focus:outline-none" />
                
             
                <img src="/assets/images/map-pin.png" alt="Location Icon" className="w-6 h-6 mx-2" />
                
         
                {/* <select className="bg-white text-gray-800 pr-3 py-2 focus:outline-none">
                    <option value="">Select Location</option>
                    <option value="location1">Location 1</option>
                    <option value="location2">Location 2</option>
                    <option value="location3">Location 3</option>
                </select> */}
    
            
                 <div className="p-1">
                    <button className="bg-[#850C85] rounded-md text-white px-4 py-2 hover:bg-purple-700 transition hidden md:block">Search</button>
                 </div>
                
            </div>
    
          
            <div className="w-full md:hidden mb-4">
                <button className="bg-[#850C85] rounded-md text-white px-6 py-2 hover:bg-purple-700 transition w-full">Search</button>
            </div>
            
            
            
            
            
            <div className="flex items-center w-full">
                <img src="/assets/images/customer-satisfaction-badge.png" alt="Badge" className="w-10 h-10 mr-4" />
                <span className="text-[#1A121A] text-lg font-semibold">100% Customer Satisfaction</span>
            </div>
        </div>



        {/* <section className="py-20 bg-white">
            <div className="max-w-screen-xl mx-auto px-4">
                <h2 className="text-center text-3xl md:text-4xl font-bold mb-12">Featured Services</h2>
                <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
           
           
              <a href="/categories/fashion">
                    <div className="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/assets/images/fashion.png')"}}>
                        <div className="absolute bottom-0 left-0 right-0  bg-opacity-50 p-4">
                            <span className="text-white text-xl font-semibold">Fashion</span>
                        </div>
                    </div>
                    </a>

                    <a href="/categories/repairs-service">
                    <div className="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/assets/images/repair-&-service.png')"}}>
                        <div className="absolute bottom-0 left-0 right-0  bg-opacity-50 p-4">
                            <span className="text-white text-xl font-semibold">Repair & Service</span>
                        </div>
                    </div>
                    </a>

                </div>
            </div>
        </section> */}
        


        <section className="py-20" style={{"background": "linear-gradient(283deg, #240A24 0%, #4F204F 100%)"}}>
            <div className="max-w-screen-xl mx-auto px-4 flex flex-col md:flex-row items-center">
           
                <div className="md:w-3/5 mb-8 md:mb-0 pr-4">
                    <h2 className="text-white text-3xl md:text-4xl font-bold mb-4">How It Works</h2>
                    <p className="text-gray-300 text-lg mb-8">
                        A trusted professional arrives to complete the job, leaving you with peace of mind.
                    </p>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                      
                        <div className="flex items-center">
                            <div className="relative mr-4 flex-shrink-0 flex items-center">
                                <span className="text-white text-[40px] font-bold">01</span>
                                <span className="absolute text-[#850C85] text-[39px] font-bold top-0 left-0">01</span>
                            </div>
                            <div className="flex flex-col justify-center">
                                <h3 className="text-2xl font-semibold text-white mb-2">Choose Your Service</h3>
                                <p className="text-white text-sm">Find all the services your home needs with us. Simply inquire to choose your preferred options.</p>
                            </div>
                        </div>
            
                   
                        <div className="flex items-center">
                            <div className="relative mr-4 flex-shrink-0 flex items-center">
                                <span className="text-white text-[40px] font-bold">02</span>
                                <span className="absolute text-[#850C85] text-[39px] font-bold top-0 left-0">02</span>
                            </div>
                            <div className="flex flex-col justify-center">
                                <h3 className="text-2xl font-semibold text-white mb-2">Book a Professional</h3>
                                <p className="text-white text-sm">Easily schedule trusted professionals for your tasks, ensuring quality service at your preferred time and convenience.</p>
                            </div>
                        </div>
            
                  
                        <div className="flex items-center">
                            <div className="relative mr-4 flex-shrink-0 flex items-center">
                                <span className="text-white text-[40px] font-bold">03</span>
                                <span className="absolute text-[#850C85] text-[39px] font-bold top-0 left-0">03</span>
                            </div>
                            <div className="flex flex-col justify-center">
                                <h3 className="text-2xl font-semibold text-white mb-2">Get the Job Done</h3>
                                <p className="text-white text-sm">Your chosen professional arrives on time to complete the task with efficiency and care.</p>
                            </div>
                        </div>
            
            
                        <div className="flex items-center">
                            <div className="relative mr-4 flex-shrink-0 flex items-center">
                                <span className="text-white text-[40px] font-bold">04</span>
                                <span className="absolute text-[#850C85] text-[39px] font-bold top-0 left-0">04</span>
                            </div>
                            <div className="flex flex-col justify-center">
                                <h3 className="text-2xl font-semibold text-white mb-2">Make Payment</h3>
                                <p className="text-white text-sm">Easily pay through secure methods once the job is completed to your satisfaction.</p>
                            </div>
                        </div>
                    </div>
                </div>
        
            
                <div className="md:w-2/5">
                    <img src="/assets/images/how-its-works.png" alt="How It Works" className="w-3/4 h-auto rounded-lg mx-auto" />
                </div>
            </div>
        </section>
        
        
        



        <section className="py-20 bg-gray-100">
            <div className="max-w-screen-xl mx-auto px-4">
                <h2 className="text-center text-3xl md:text-4xl font-bold mb-12">Service For You</h2>
                <div className="grid grid-cols-2 gap-4 md:grid-cols-4 pb-4">
                {/* <a href="/categories/transportation">
                    <div className="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/assets/images/transpotation.png')"}}>
                        <div className="absolute bottom-0 left-0 right-0  bg-opacity-50 p-4">
                            <span className="text-white text-xl font-semibold">Transpotation</span><br />
                            <span class="text-white text-xl font-normal">Starts &#8377;199</span>
                        </div>
                    </div>
                    </a> */}
        
             
                    {/* <div className="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/assets/images/buy&sell.png')"}}>
                        <div className="absolute bottom-0 left-0 right-0  bg-opacity-50 p-4">
                            <span className="text-white text-xl font-semibold">Buy & Sell</span>
                        </div>
                    </div> */}
        
                    <a href="/sub-category/home-renovation">
                    <div className="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/assets/images/home-service.png')"}}>
                        <div className="absolute bottom-0 left-0 right-0  bg-opacity-50 p-4">
                            <span className="text-white text-xl font-semibold">Home Services</span><br />
                            <span class="text-white text-xl font-normal">Starts &#8377;99</span>
                        </div>
                    </div>
                    </a>
        
                    {/* <a href="/categories/beauty-care">
                    <div className="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/assets/images/beauty&care.png')"}}>
                        <div className="absolute bottom-0 left-0 right-0  bg-opacity-50 p-4">
                            <span className="text-white text-xl font-semibold">Beauty & Care</span>
                        </div>
                    </div>
                    </a> */}
                    {/* <a href="/categories/fashion">
                    <div className="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/services-img/images/fashion.png')"}}>
                        <div className="absolute bottom-0 left-0 right-0  bg-opacity-50 p-4">
                            <span className="text-white text-xl font-semibold">Fashion</span><br />
                            <span class="text-white text-xl font-normal">Starts &#8377;599</span>
                        </div>
                    </div>
                    </a> */}
                    {/* <a href="#">
                    <div className="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/assets/images/training&skillup.png')"}}>
                        <div className="absolute bottom-0 left-0 right-0  bg-opacity-50 p-4">
                            <span className="text-white text-xl font-semibold">Training & Skillup</span>
                        </div>
                    </div>
                    </a> */}
                     {/* <a href="/sub-category/finance">
                    <div className="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/assets/images/banking.png')"}}>
                        <div className="absolute bottom-0 left-0 right-0  bg-opacity-50 p-4">
                            <span className="text-white text-xl font-semibold">Banking</span><br />
                            <span class="text-white text-xl font-normal">Starts &#8377;499</span>
                        </div>
                    </div>
                    </a> */}
                </div>
                
                <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
              
        
                  
        
                   
        
                    {/* <a href="/categories/jobs">
                    <div className="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/assets/images/jobs.png')"}}>
                        <div className="absolute bottom-0 left-0 right-0  bg-opacity-50 p-4">
                            <span className="text-white text-xl font-semibold">Jobs</span>
                        </div>
                    </div>
                    </a> */}
                </div>
            </div>
        </section>


        {/* <section className="py-20 bg-gray-100">
            <div className="max-w-screen-xl mx-auto px-4">


            <OwlCarousel className="owl-carousel owl-theme service-carousal" {...options}>

        <div className="bg-black text-white rounded-xl py-6 pl-6 flex md:flex-row flex-col md:items-stretch h-auto md:h-48">
                   
                   <div className="md:w-3/5 mb-4 md:mb-0 pr-6 md:pr-0 flex items-center">
                       <div className="flex flex-col justify-center h-full">
                           <h3 className="text-2xl font-semibold mb-2">Let’s go travel the world!</h3>
                           <p className="text-gray-300">Begin your journey with the most budget-friendly cab service</p>
                       </div>
                   </div>
               
                   <div className="md:w-2/5 flex items-center justify-center md:block">
                       <img src="/assets/images/taxi.png" alt="Service" className="h-32 w-32 mx-auto md:w-auto md:h-auto" />
                   </div>
               </div>
               <div className="bg-[#107C76] text-white rounded-xl py-6 px-6 flex flex-col md:flex-row h-auto md:h-48">
                   
                   <div className="md:w-3/5 mb-4 md:mb-0 items-center flex">
                       <div className="flex flex-col justify-center h-full">
                           <h3 className="text-2xl font-semibold mb-2">Let’s go travel the world!</h3>
                           <p className="text-gray-300">Begin your journey with the most budget-friendly cab service</p>
                       </div>
                   </div>
             
                   <div className="md:w-2/5 flex items-center justify-center">
                       <img src="/assets/images/car-wheel.png" alt="Service" className="h-32 w-auto hidden md:block" />
                   </div>
               </div>
               <div className="bg-black text-white rounded-xl py-6 pl-6 flex md:flex-row flex-col md:items-stretch h-auto md:h-48">
                    
                    <div className="md:w-3/5 mb-4 md:mb-0 pr-6 md:pr-0 flex items-center">
                        <div className="flex flex-col justify-center h-full">
                            <h3 className="text-2xl font-semibold mb-2">Let’s go travel the world!</h3>
                            <p className="text-gray-300">Begin your journey with the most budget-friendly cab service</p>
                        </div>
                    </div>
               
                    <div className="md:w-2/5 flex items-center justify-center md:block">
                        <img src="/assets/images/taxi.png" alt="Service" className="h-32 w-32 mx-auto md:w-auto md:h-auto" />
                    </div>
                </div>
                <div className="bg-[#107C76] text-white rounded-xl py-6 px-6 flex flex-col md:flex-row h-auto md:h-48">
                    
                        <div className="md:w-3/5 mb-4 md:mb-0 items-center flex">
                            <div className="flex flex-col justify-center h-full">
                                <h3 className="text-2xl font-semibold mb-2">Let’s go travel the world!</h3>
                                <p className="text-gray-300">Begin your journey with the most budget-friendly cab service</p>
                            </div>
                        </div>
                    
                        <div className="md:w-2/5 flex items-center justify-center">
                            <img src="/assets/images/car-wheel.png" alt="Service" className="h-32 w-auto hidden md:block" />
                        </div>
                    </div>
        
              
                    <div className="bg-black text-white rounded-xl py-6 pl-6 flex md:flex-row flex-col md:items-stretch h-auto md:h-48">
                  
                        <div className="md:w-3/5 mb-4 md:mb-0 pr-6 md:pr-0 flex items-center">
                            <div className="flex flex-col justify-center h-full">
                                <h3 className="text-2xl font-semibold mb-2">Let’s go travel the world!</h3>
                                <p className="text-gray-300">Begin your journey with the most budget-friendly cab service</p>
                            </div>
                        </div>
                   
                        <div className="md:w-2/5 flex items-center justify-center md:block">
                            <img src="/assets/images/taxi.png" alt="Service" className="h-32 w-32 mx-auto md:w-auto md:h-auto" />
                        </div>
                    </div>
      </OwlCarousel>

            </div>
        </section>   */}


        {/* <section className="py-20 bg-gray-100">
            <div className="max-w-screen-xl mx-auto px-4">
                <div className="flex flex-col md:flex-row md:gap-8">
            
                    <div className="md:w-2/5 mb-8 md:mb-0 flex items-center">
                        <div>
                            <h2 className="text-3xl font-bold mb-4">Travel And Booking</h2>
                            <p className="text-gray-700">A trusted professional arrives to complete the job, leaving you with peace of mind.</p>
                        </div>
                    </div>
        
              
                    <div className="md:w-3/5 flex items-center gap-4">
                  
                        <div className="w-1/2">
                            <div className="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/assets/images/bus-booking.png')"}}>
                                <div className="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                    <span className="text-white text-xl font-semibold">Bus Booking</span>
                                </div>
                            </div>
                        </div>
        
                 
                        <div className="w-1/2">
                            <div className="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/assets/images/flight-booking.png')"}}>
                                <div className="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                    <span className="text-white text-xl font-semibold">Flight Booking</span>
                                </div>
                            </div>
                        </div>
        
                     
                        <div className="w-1/2 overflow-hidden">
                            <div className="relative" style={{"marginRight": "-50%"}}>
                                <div className="bg-cover bg-center rounded-xl overflow-hidden h-80 relative" style={{"backgroundImage": "url('/assets/images/hotel-booking.png')"}}>
                                    <div className="absolute bottom-0 left-0 right-0 bg-opacity-50 p-4">
                                        <span className="text-white text-xl font-semibold">Hotel Booking</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}





<section className="py-20 bg-white">
  <div className="max-w-screen-xl mx-auto px-4">
    <h2 className="text-3xl font-bold mb-8 text-center">Proof of Excellence</h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 pb-6">
      
      <div className="border rounded-xl p-6 bg-white">
        <div className="flex items-center mb-4">
          <img src="/assets/images/reviewr-img.png" alt="Reviewer" className="w-12 h-12 rounded-full object-cover mr-4" />
          <div>
            <h3 className="font-bold text-[#850C85]">Amit Sharma</h3>
            <span className="text-gray-600">Mechanic</span>
          </div>
        </div>
        <p className="text-gray-700 mb-4">The service was quick and reliable. They fixed my car issues within an hour. Highly recommend their expertise and professionalism!</p>
        <div className="text-center">
          <span className="text-gray-500">January 15, 2024</span>
        </div>
      </div>
      
      {/* <div className="border rounded-xl p-6 bg-white">
        <div className="flex items-center mb-4">
          <img src="/assets/images/reviewr-img.png" alt="Reviewer" className="w-12 h-12 rounded-full object-cover mr-4" />
          <div>
            <h3 className="font-bold text-[#850C85]">Sneha Iyer</h3>
            <span className="text-gray-600">Beauty Care</span>
          </div>
        </div>
        <p className="text-gray-700 mb-4">Their beauty care services were exceptional. The beautician was very professional and gave great suggestions for my skin type.</p>
        <div className="text-center">
          <span className="text-gray-500">February 8, 2024</span>
        </div>
      </div> */}
      
      <div className="border rounded-xl p-6 bg-white">
        <div className="flex items-center mb-4">
          <img src="/assets/images/reviewr-img.png" alt="Reviewer" className="w-12 h-12 rounded-full object-cover mr-4" />
          <div>
            <h3 className="font-bold text-[#850C85]">Ravi Kumar</h3>
            <span className="text-gray-600">Technician</span>
          </div>
        </div>
        <p className="text-gray-700 mb-4">I was impressed by the technician’s skills. They fixed my AC quickly and explained the issue clearly. Fantastic service!</p>
        <div className="text-center">
          <span className="text-gray-500">March 12, 2024</span>
        </div>
      </div>
      
    </div>
    {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      
      <div className="border rounded-xl p-6 bg-white">
        <div className="flex items-center mb-4">
          <img src="/assets/images/reviewr-img.png" alt="Reviewer" className="w-12 h-12 rounded-full object-cover mr-4" />
          <div>
            <h3 className="font-bold text-[#850C85]">Priya Gupta</h3>
            <span className="text-gray-600">Banking Jobs</span>
          </div>
        </div>
        <p className="text-gray-700 mb-4">Thanks to their guidance, I secured a banking job. Their tips and interview prep sessions were very effective!</p>
        <div className="text-center">
          <span className="text-gray-500">April 20, 2024</span>
        </div>
      </div>
      
      <div className="border rounded-xl p-6 bg-white">
        <div className="flex items-center mb-4">
          <img src="/assets/images/reviewr-img.png" alt="Reviewer" className="w-12 h-12 rounded-full object-cover mr-4" />
          <div>
            <h3 className="font-bold text-[#850C85]">Karan Desai</h3>
            <span className="text-gray-600">Mechanic</span>
          </div>
        </div>
        <p className="text-gray-700 mb-4">The team is very skilled and efficient. They replaced my bike’s clutch system in no time. Will definitely call them again!</p>
        <div className="text-center">
          <span className="text-gray-500">May 5, 2024</span>
        </div>
      </div>
      
      <div className="border rounded-xl p-6 bg-white">
        <div className="flex items-center mb-4">
          <img src="/assets/images/reviewr-img.png" alt="Reviewer" className="w-12 h-12 rounded-full object-cover mr-4" />
          <div>
            <h3 className="font-bold text-[#850C85]">Anjali Menon</h3>
            <span className="text-gray-600">Beauty Care</span>
          </div>
        </div>
        <p className="text-gray-700 mb-4">Loved their bridal makeup service. They made my big day even more special. The makeup artist was very talented!</p>
        <div className="text-center">
          <span className="text-gray-500">June 10, 2024</span>
        </div>
      </div>
      
    </div> */}
  </div>
</section>

        


    {/* <section className="py-20  bg-gray-100 relative">
        <svg className="absolute top-0 left-0 w-full" viewBox="0 0 1440 100" preserveAspectRatio="none">
            <path fill="#f9fafb" d="M0,0 C200,100 400,0 600,0 C800,0 1000,100 1200,0 C1400,0 1600,100 1800,0 L1800,0 L0,0 Z"></path>
            <path fill="#f9fafb" d="M0,0 C200,100 400,0 600,0 C800,0 1000,100 1200,0 C1400,0 1600,100 1800,0 L1800,0 L0,0 Z"></path>
        </svg>
        <div className="max-w-screen-xl mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">Snap</h2>
            <div className="owl-carousel owl-theme snap-carousel">
         
                <div className="item">
                    <div className="bg-black text-white rounded-xl p-6 flex flex-col">
                        <video src="https://www.w3schools.com/html/mov_bbb.mp4" controls className="w-full h-72 rounded-lg mb-4"></video>
                        <h3 className="font-semibold">10k views</h3>
                    </div>
                </div>
       
                <div className="item">
                    <div className="bg-black text-white rounded-xl p-6 flex flex-col">
                        <video src="https://www.w3schools.com/html/mov_bbb.mp4" controls className="w-full h-72 rounded-lg mb-4"></video>
                        <h3 className="font-semibold">10k views</h3>
                    </div>
                </div>
     
                <div className="item">
                    <div className="bg-black text-white rounded-xl p-6 flex flex-col">
                        <video src="https://www.w3schools.com/html/mov_bbb.mp4" controls className="w-full h-72 rounded-lg mb-4"></video>
                        <h3 className="font-semibold">10k views</h3>
                    </div>
                </div>
              
                <div className="item">
                    <div className="bg-black text-white rounded-xl p-6 flex flex-col">
                        <video src="https://www.w3schools.com/html/mov_bbb.mp4" controls className="w-full h-72 rounded-lg mb-4"></video>
                        <h3 className="font-semibold">10k views</h3>
                    </div>
                </div>
         
                <div className="item">
                    <div className="bg-black text-white rounded-xl p-6 flex flex-col">
                        <video src="https://www.w3schools.com/html/mov_bbb.mp4" controls className="w-full h-72 rounded-lg mb-4"></video>
                        <h3 className="font-semibold">10k views</h3>
                    </div>
                </div>
              
                <div className="item">
                    <div className="bg-black text-white rounded-xl p-6 flex flex-col">
                        <video src="https://www.w3schools.com/html/mov_bbb.mp4" controls className="w-full h-72 rounded-lg mb-4"></video>
                        <h3 className="font-semibold">10k views</h3>
                    </div>
                </div>                

            </div>
        </div>
    </section> */}

<Footer />
    
        
        
    </section>

        </>
    );
}

export default Home;
