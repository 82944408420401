import { Navigate, useNavigate, useParams } from "react-router-dom";
import Footer from "../Components/Footer";
import Innernavbar from "../Components/Innernavbar";
import {Helmet} from 'react-helmet';
import { useEffect, useState } from "react";
import apiService from "../apiService";
import Navbar from '../Components/Navbar';
import './output.css';

function PartnerStore(){
    const { emp_id } = useParams();
    const [get_data, setget_data] = useState([]);

    useEffect(()=>{
       get_user_data(emp_id)
    },[])

    const get_user_data = async(emp_id)=>{
        var emp_idd = {
            "emp_id":emp_id
        }
        const ar = await apiService.get_partner_data(emp_idd);
        
        var data = ar["data"];
        setget_data(data["details"]);
    }
    return(
        <>
         
    
    <Navbar />



    <div class="relative max-w-7xl mx-auto px-2 md:px-4 mt-20 md:mt-4">
    <div class="h-[500px] md:h-[600px] bg-cover bg-center bg-no-repeat rounded-xl" style={{ backgroundImage: `url(${get_data.featured_image})` }}></div>
        {/* <div class="owl-carousel owl-theme">
            <div class="item">
                <div class="h-[500px] md:h-[600px] bg-cover bg-center bg-no-repeat rounded-xl" style={{ backgroundImage: `url(${get_data.featured_image})` }}></div>
            </div>
         
        </div> */}
    </div>


    <div class="max-w-7xl mx-auto px-2 md:px-4 py-10 mb-4"> 
        <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
            
            <div class="flex flex-col gap-4 md:col-span-2">
                <div class=" bg-white border border-[#D9D9D9] rounded-xl p-2 md:p-6  overflow-y-auto">

                    <div class="flex flex-col gap-4">
                        {/* <div>
                            <h2 class="text-2xl font-bold mb-4">About </h2>
                            <p class=" mb-4">
                                Welcome to Krishna Carpenters! With a rich tradition of craftsmanship and a passion for quality, we bring your woodworking visions to life. Our expert team combines years of experience with innovative techniques to deliver exceptional custom furniture, home renovations, and repairs.
                            </p>
                            <p class=" mb-4">
                                We take pride in our attention to detail and personalized service, ensuring each project reflects your unique style and needs. From elegant cabinetry to durable structures, our commitment to excellence and customer satisfaction drives everything we do.
                            </p>
                            <p class=" mb-4">
                                Experience the art of fine carpentry with Krishna Carpenters, where every creation is crafted with precision and care.
                            </p>
                        </div> */}
                        
                        {/* <div>
                            <h2 class="text-2xl font-bold mb-4">Availability  </h2>
                            <div class="flex gap-10 md:gap-20">
                                <div>
                                    <p class="mb-2">Weekly Schedule</p>
                                    <p class=" text-[#850C85]">Monday - Saturday</p>
                                </div>

                                <div>
                                    <p class="mb-2">Time</p>
                                    <p class=" text-[#850C85]">09:00 AM  - 10:00 PM</p>
                                </div>

                            </div>
                        </div> */}

                 
                        <div>
                            <h2 class="text-2xl font-bold mb-4">Property Location</h2>
                            {/* <div class="w-full h-[300px] rounded-xl overflow-hidden mb-4">
                                
                               
                            </div> */}
                            <div class="flex gap-2">
                                <img src="/assets/images/images/location.png" alt="" class="h-6 w-6" />
                                <p class="text-[#555555]">{get_data.service_address}</p>
                            </div>
                        </div>

                     
                         <div>
                            <h2 class="text-2xl font-bold mb-4">Services & Details</h2>
                            <div class="flex flex-col md:flex-row gap-4 md:gap-20">
                                <div>
                                
                                    <div class="flex gap-10">
                                        <div class="flex flex-col gap-2">
                                        
                                        {get_data.all_categories ? (
                                            get_data.all_categories.map((ls, key) => (
                                                <p key={key}>{ls.name}</p>
                                            ))
                                            ) : (
                                            <></>
                                            )}
                                           
                                           

                                        </div>
                                       
                                    </div>
                                </div>
                              
                            </div>
                         </div>


                    </div>

                    
                    
                </div>
    
                <div class=" bg-white  p-2 md:p-6  overflow-y-auto">
                    <h2 class="text-2xl font-bold mb-4">Review & Ratings</h2>
                    <div class="flex justify-between mb-4">
                        <div class="flex gap-2 align-bottom items-center">
                            <h1 class="text-4xl font-bold">{get_data.ratings_average}</h1>
                            <img src="/assets/images/images/Star.png" alt="" class="h-4 w-4" />
                            {/* <p>(1248 Ratings)</p> */}
                        </div>
                        <div>
                            <button class="bg-[#850C85] text-white font-semibold py-2 px-6 rounded-xl shadow-lg hover:bg-purple-700 transition duration-300 ease-in-out">
                                Add Your Review
                            </button>
                        </div>
                        
                    </div>
                    
                    <div>
                        <div class="review-carousel owl-carousel owl-theme">
                      
                            <div class="item bg-white shadow-lg rounded-xl p-6 border border-[#D9D9D9]">
                         
                                <div class="flex items-center mb-4 gap-4">
                             
                                    <div class="flex-shrink-0">
                                        <img src="/assets/images/images/facebook.png" alt="Reviewer Image" class="w-12 h-12 rounded-full object-cover" />
                                    </div>
                                
                       
                                    <div>
                                        <h3 class="text-lg font-bold">John Doe</h3>
                                
                                        <div class="flex items-center">
                                            <span class="text-yellow-500">★★★★★</span>
                                        </div>
                                    </div>
                                </div>
                        
                    
                                <div class="mb-4">
                                    <p class="text-sm text-gray-600">
                                        "Excellent service! Highly recommended for custom furniture. The quality is outstanding, and the team was very professional."
                                    </p>
                                </div>
                        
                    
                                <div class="flex space-x-2">
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 1" class="w-16 h-16 rounded-lg object-cover" />
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 2" class="w-16 h-16 rounded-lg object-cover" />
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 3" class="w-16 h-16 rounded-lg object-cover" />
                                </div>
                            </div>
                            
                      
                            <div class="item bg-white shadow-lg rounded-xl p-6 border border-[#D9D9D9]">
                     
                                <div class="flex items-center mb-4 gap-4">
                             
                                    <div class="flex-shrink-0">
                                        <img src="/assets/images/images/facebook.png" alt="Reviewer Image" class="w-12 h-12 rounded-full object-cover" />
                                    </div>
                                
                           
                                    <div>
                                        <h3 class="text-lg font-bold">John Doe</h3>
                                 
                                        <div class="flex items-center">
                                            <span class="text-yellow-500">★★★★★</span>
                                        </div>
                                    </div>
                                </div>
                        
                            
                                <div class="mb-4">
                                    <p class="text-sm text-gray-600">
                                        "Excellent service! Highly recommended for custom furniture. The quality is outstanding, and the team was very professional."
                                    </p>
                                </div>
                        
                             
                                <div class="flex space-x-2">
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 1" class="w-16 h-16 rounded-lg object-cover" />
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 2" class="w-16 h-16 rounded-lg object-cover" />
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 3" class="w-16 h-16 rounded-lg object-cover" />
                                </div>
                            </div>
                        
                    
                            <div class="item bg-white shadow-lg rounded-xl p-6 border border-[#D9D9D9]">
                          
                                <div class="flex items-center mb-4 gap-4">
                             
                                    <div class="flex-shrink-0">
                                        <img src="/assets/images/images/facebook.png" alt="Reviewer Image" class="w-12 h-12 rounded-full object-cover" />
                                    </div>
                                
                              
                                    <div>
                                        <h3 class="text-lg font-bold">John Doe</h3>
                                
                                        <div class="flex items-center">
                                            <span class="text-yellow-500">★★★★★</span>
                                        </div>
                                    </div>
                                </div>
                        
                         
                                <div class="mb-4">
                                    <p class="text-sm text-gray-600">
                                        "Excellent service! Highly recommended for custom furniture. The quality is outstanding, and the team was very professional."
                                    </p>
                                </div>
                        
                         
                                <div class="flex space-x-2">
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 1" class="w-16 h-16 rounded-lg object-cover" />
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 2" class="w-16 h-16 rounded-lg object-cover" />
                                    <img src="/assets/images/images/hero-subimage2.png" alt="Product Image 3" class="w-16 h-16 rounded-lg object-cover" />
                                </div>
                            </div>
                        
                     
                        </div>
                        
                    </div>
                </div>


             
                <div class=" bg-white  p-2 md:p-6  overflow-y-auto">
                    <h2 class="text-2xl font-bold mb-4">Cancellation Policy</h2>
                    <div class="flex gap-2 align-middle">
                        <img src="/assets/images/images/cancellation.png" alt="" class="h-5 w-5" />
                        <p class="text-[#E94444]">You will incur a ₹10 charge if you cancel the order after it has been accepted by the
                            vendor.</p>
                    </div>

                   
                    
                </div>
            </div>

    
         
            <div class="  h-fit  sticky bg-none top-5">
                <div class="bg-white shadow-lg rounded-xl mb-4 p-2 md:p-6">
                    <h2 class="text-2xl font-bold mb-4 ">Store Details</h2>
                    <p class="font-semibold mb-2 text-md">{get_data.service_name} </p>
                    <p class="mb-2 text-md"><span class="font-semibold">Owner : </span>{get_data.first_name}</p>
                    <p class="mb-2 text-md"><span class="font-semibold">Joined on : </span>July 26, 2018</p>
                    {/* <p class="mb-2 text-md"><span class="font-semibold">GSTIN : </span>19AFRDRS7838L1ZQ</p> */}
                    <p class="mb-2 text-md"><span class="font-semibold">Address : </span>{get_data.service_address}</p>
                    <div class="my-4"><hr /></div>
                    <h3 class="text-xl font-bold mb-4">Share</h3>
                    <div class="flex justify-center md:justify-start space-x-4">
                  
                        <div class="flex flex-col items-center space-y-2 border p-2 rounded-lg">
                            <a href="https://www.facebook.com/sharer/sharer.php?u=https://reliep.com/partner/store/RP-1013" target="_blank">
                                <img src="/assets/images/images/facebook.png" alt="Facebook" class="w-8 h-8" />
                            </a>
                            <p class="text-sm font-semibold">Facebook</p>
                        </div>
                
                  
                        <div class="flex flex-col items-center space-y-2 border p-2 rounded-lg">
                            <a href="https://wa.me/?text=https://reliep.com/partner/store/RP-1013" target="_blank">
                                <img src="/assets/images/images/whatsapp-logo.png" alt="WhatsApp" class="w-8 h-8" />
                            </a>
                            <p class="text-sm font-semibold">WhatsApp</p>
                        </div>
                
                 
                        <div class="flex flex-col items-center space-y-2 border p-2 rounded-lg">
                            <button onclick="copyToClipboard()">
                                <img src="/assets/images/images/copy-link-logo.png" alt="Copy Link" class="w-8 h-8" />
                            </button>
                            <p class="text-sm font-semibold">Copy Link</p>
                        </div>
                    </div>
                </div>

                <div class="rounded-xl">
                    <img src="/assets/images/images/reward.png" alt="" />
                </div>

               
            </div>
        </div>
    </div>



    




<div class="fixed bottom-0 left-0 right-0 bg-white shadow-lg z-50 p-4  rounded-t-2xl border-t border-purple-500 flex flex-col md:flex-row md:justify-between">


    <div class="w-full md:w-1/2 mb-4 md:mb-0 flex flex-col space-y-2">
   
        <div class="text-2xl font-bold">{get_data.service_name}</div>
     
         <div class="flex gap-4">
            <div class="flex items-center space-x-2 ">
                <img src="/assets/images/images/Star.png" alt="Star Rating" class="w-5 h-5" />
                <span class="text-sm font-semibold"><p class="text-[#555555]">{get_data.ratings_average}/5</p></span>
            </div>
          
            <div class="text-sm text-gray-700"><span class="text-[#28892D]">Opens at</span> 09:00 AM</div>
         </div>

    </div>

  
    <div class="w-full md:w-1/2 flex flex-col md:flex-row space-y-2 md:space-y-0 space-x-0 md:space-x-2">
    
        <button class="bg-[#850C85] text-white py-2 px-4 rounded-xl flex items-center justify-center w-full md:w-1/2  transition duration-300">
            <img src="/assets/images/images/book-now.png" alt="Book Now Icon" class="w-5 h-5 mr-2" />
            <span>Book Now</span>
        </button>

      
        <button class="bg-white text-[#850C85] py-2 px-4 rounded-xl flex items-center justify-center w-full md:w-1/2 border border-[#850C85]  transition duration-300">
            <img src="/assets/images/images/notify-vendor.png" alt="Notify Me Icon" class="w-5 h-5 mr-2" />
            <span>Notify Me</span>
        </button>
    </div>
</div>
   

    

     <Footer />
        </>
    )
}
export default PartnerStore;