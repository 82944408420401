import { Navigate, useNavigate, useParams } from "react-router-dom";
import {Helmet} from 'react-helmet';
import { useEffect, useState } from "react";
import apiService from "../apiService";
import Navbar from '../Components/Navbar';
function Categoryservices(){
    const { slug } = useParams();

    const [get_data, setget_data] = useState([])
    const [cat, setcat] = useState("")
    const [cnt, setcnt] = useState(0)

    useEffect(()=>{
        category_service_data(slug)
    },[])

    const category_service_data = async(slug)=>{
        var ary = {
            "slug":slug,
            "location":""
        }
          const get_data = await apiService.get_cat_service_data(ary);
        //  console.log(get_data);
         
          var dt = get_data["data"];
          var ddt = dt["main-category"];
          setcat(ddt);
          setcnt(dt["count"]);
          setget_data(dt["data"]);
    }

return(
    <>


<Helmet>
        <link rel="stylesheet" href="/assets/css/categoryservice.css" />
      </Helmet>
<Navbar />



    <div class="flex flex-col md:flex-row  max-w-7xl mx-auto mt-20 md:mt-4">
        <div class="w-full md:w-1/3  p-4">
    
          <div class="  h-fit top-5 flex flex-col gap-4">
            <div class="border rounded-xl bg-white py-6 px-4">
                <h2 class="text-4xl font-bold">{cat.name}</h2>
                <p class="pt-4">We found {cnt} result<span>{cnt>1?"s":""}</span> around you.</p>
            </div>
            <div class="border rounded-xl bg-white py-6 px-4">
                <h2 class="text-2xl font-bold mb-4">What we Promise?</h2>
                <div class="mt-2 flex items-center">
                    <img src="/assets/images/cat/excellent-service.png" alt="Star Icon" class="h-5 w-5" />
                    <span class="ml-4">Excellent Service</span>
                </div>
                <div class="mt-2 flex items-center">
                    <img src="/assets/images/cat/genuine-product.png" alt="Star Icon" class="h-5 w-5" />
                    <span class="ml-4">Genuine Products</span>
                </div>
                <div class="mt-2 flex items-center">
                <img src="/assets/images/cat/value-for-money.png" alt="Star Icon" class="h-5 w-5" />
                <span class="ml-4">Value for Money</span>
                </div>
            </div>     

            <div class="rounded-xl">
                <img src="/assets/images/cat/get-reward.png" alt="" />
            </div>

           
        </div>
        </div>
        
        <div class="w-full md:w-2/3  p-4 flex flex-col gap-4">
     

        {get_data.map((gd, index)=>{
            return(
                <>
                <a href={`/partner/store/${gd.emp_id}`}>
                          <div class="border rounded-xl bg-white p-4">
            <div class="flex items-stretch">
              <div class="w-1/3">
                <img src={gd.featured_image} alt="Store Image" class="rounded-xl w-full h-full object-cover" style={{"height":"200px"}} />
              </div>
              <div class="w-2/3 pl-4 flex flex-col justify-between">
          
                <div class="flex justify-between items-center">
                  <h2 class="text-3xl font-bold">{gd.service_name}</h2>
                  <button class="text-red-500 hover:text-red-700">
                    <img src="/assets/images/cat/love.png" alt="Love Icon" class="h-5 w-5" />
                  </button>
                </div>
          
              
                <div class="mt-2 flex items-center">
                  <img src="/assets/images/cat/Star.png" alt="Star Icon" class="h-4 w-4" />
                  <span class="ml-1">({gd.ratings_average}/5 stars)</span>
                </div>
          
                <div class=" flex items-center">
                  <img src="/assets/images/cat/location.png" alt="Location Icon" class="h-5 w-5 mr-1" />
                  <span>{gd.service_address}</span>
                </div>
          
      
                <div class="mt-10">
                  <button class="text-[#850C85] font-bold">
                    View Details
                  </button>
                </div>
              </div>
            </div>
          </div>
          </a>
                </>
            )
        })}


          
        </div>
    </div>
    </>
)
}
export default Categoryservices;