import { Helmet } from "react-helmet";
import Footer from "../Components/Footer";
import Innernavbar from "../Components/Innernavbar";
import Navbar from "../Components/Navbar";

function Privacy_policy(){
    return(
        <>


<Helmet>
        <link rel="stylesheet" href="/assets/css/about.css" />
      </Helmet>

<Navbar />


<section class="container max-w-7xl mx-auto py-16 px-4 flex">
  <div class="container privacy">
    <header>
      <h1>Privacy Policy</h1>
    </header>
    <section>
      <h2>1. Introduction</h2>
      <p>Welcome to our privacy policy. Your privacy is critically important to us. This policy outlines how we collect, use, and protect your personal information when you use our services or interact with our website.</p>
    </section>
    <section>
      <h2>2. Information We Collect</h2>
      <p>We may collect the following types of information:</p>
      <ul>
        <li><strong>Personal Information:</strong> Name, email address, phone number, shipping address, and payment details when you create an account, place an order, or contact us.</li>
        <li><strong>Usage Information:</strong> Data on how you interact with our website, such as pages visited, time spent on the site, and links clicked.</li>
        <li><strong>Device Information:</strong> Details about the device you use to access our website, including IP address, browser type, and operating system.</li>
        <li><strong>Cookies and Tracking Technologies:</strong> Information collected through cookies and similar technologies to enhance user experience and analyze website performance.</li>
      </ul>
    </section>
    <section>
      <h2>3. How We Use Your Information</h2>
      <p>Your information is used for the following purposes:</p>
      <ul>
        <li>To process and fulfill orders, including payment processing.</li>
        <li>To provide customer support and respond to your inquiries.</li>
        <li>To personalize your experience and improve our services.</li>
        <li>To send you marketing communications, promotional offers, and updates (with your consent).</li>
        <li>To analyze website usage and improve functionality.</li>
        <li>To comply with legal obligations and enforce our terms and policies.</li>
      </ul>
    </section>
    <section>
      <h2>4. Information Sharing</h2>
      <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except:</p>
      <ul>
        <li>To service providers who assist us in operating our website and services (e.g., payment processors, shipping companies).</li>
        <li>When required by law, such as to comply with a legal process or government request.</li>
        <li>To protect our rights, property, or safety, or that of our users or others.</li>
      </ul>
    </section>
    <section>
      <h2>5. Cookies and Tracking Technologies</h2>
      <p>We use cookies and similar technologies to:</p>
      <ul>
        <li>Recognize you on subsequent visits and provide a personalized experience.</li>
        <li>Analyze traffic and user behavior on our website.</li>
        <li>Improve website performance and functionality.</li>
      </ul>
      <p>You can control or disable cookies through your browser settings. However, disabling cookies may limit certain features of our website.</p>
    </section>
    <section>
      <h2>6. Security of Your Information</h2>
      <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.</p>
    </section>
    <section>
      <h2>7. Data Retention</h2>
      <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this policy, comply with legal obligations, resolve disputes, and enforce our agreements.</p>
    </section>
    <section>
      <h2>8. Your Rights</h2>
      <p>You have the following rights regarding your personal information:</p>
      <ul>
        <li>Access: Request a copy of the information we hold about you.</li>
        <li>Correction: Request corrections to your personal information.</li>
        <li>Deletion: Request the deletion of your data, subject to legal or contractual obligations.</li>
        <li>Opt-Out: Opt-out of receiving marketing communications.</li>
      </ul>
      <p>To exercise your rights, contact us at <a href="mailto:info@reliep.com">info@reliep.com</a>.</p>
    </section>
    <section>
      <h2>9. Third-Party Services</h2>
      <p>We may use third-party services for payment processing, analytics, and other functionalities. These third parties have their own privacy policies, and we encourage you to review them.</p>
    </section>
    <section>
      <h2>10. Changes to This Policy</h2>
      <p>We may update this privacy policy from time to time. Any changes will be posted on this page with an updated revision date.</p>
    </section>
    <footer>
      <p>This privacy policy was last updated on <span id="lastUpdated">17-01-2025</span>.</p>
      <p>Contact us at <a href="mailto:info@reliep.com">info@reliep.com</a> for any questions or concerns regarding your privacy.</p>
    </footer>
  </div>
</section>




 <Footer />



        
        </>
    )
}

export default Privacy_policy;