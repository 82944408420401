import { Helmet } from "react-helmet";
import Footer from "../Components/Footer";
import Innernavbar from "../Components/Innernavbar";
import Navbar from "../Components/Navbar";

function Terms(){
return(
    <>
    


    <Helmet>
        <link rel="stylesheet" href="/assets/css/about.css" />
      </Helmet>

<Navbar />


<section class="container max-w-7xl mx-auto py-16 px-4 flex">
  <div class="container terms">
    <header>
      <h1>Terms & Conditions</h1>
    </header>

    <section>
      <h2>1. Introduction</h2>
      <p>Welcome to our website. By accessing and using this website, you accept and agree to comply with the following terms and conditions. If you do not agree with any part of these terms, you should not use this website.</p>
    </section>

    <section>
      <h2>2. Definitions</h2>
      <p><strong>User:</strong> Any visitor or customer accessing the website.<br />
         <strong>Website:</strong> Refers to our service platform.<br />
         <strong>Products:</strong> Services available for purchase on the website.<br />
         <strong>Services:</strong> Non-physical offerings.</p>
    </section>

    <section>
      <h2>3. Website Usage</h2>
      <p><strong>Access and Use:</strong> You must be at least 18 years old to use this website. Access to certain areas may require registration.</p>
      <p><strong>Account:</strong> If you create an account, you are responsible for maintaining its confidentiality and agree to notify us of any unauthorized use. You agree to provide accurate and complete information during registration.</p>
      <p><strong>Prohibited Activities:</strong> Users are prohibited from:
        <ul>
          <li>Using the website for unlawful purposes.</li>
          <li>Engaging in activities that disrupt or interfere with the website's functionality.</li>
          <li>Uploading malicious code, such as viruses or harmful scripts.</li>
        </ul>
      </p>
    </section>

    <section>
      <h2>4. Products and Services</h2>
      <p><strong>Product Listings:</strong> We strive to provide accurate descriptions, pricing, and availability of products. However, we do not guarantee that all information is accurate, complete, or current.</p>
      <p><strong>Orders:</strong> Placing an order constitutes an offer to purchase the product at the price and terms stated. We reserve the right to refuse or cancel any order for any reason, including errors in product information or pricing.</p>
      <p><strong>Returns and Refunds:</strong> Please refer to our <a href="/refund-policy">Refund Policy</a> for details on returns, exchanges, and refunds.</p>
    </section>

    <section>
      <h2>5. Intellectual Property</h2>
      <p>All content on this website, including text, images, graphics, logos, and software, is our intellectual property or the property of our licensors. You may not reproduce, distribute, or modify any content without prior written permission.</p>
    </section>

    <section>
      <h2>6. Limitation of Liability</h2>
      <p>We are not liable for any direct, indirect, incidental, or consequential damages arising from the use or inability to use the website, products, or services, even if we have been advised of the possibility of such damages.</p>
    </section>

    <section>
      <h2>7. Privacy</h2>
      <p>Your use of this website is subject to our <a href="/privacy-policy">Privacy Policy</a>. By using the website, you consent to the collection and use of your information as outlined in the Privacy Policy.</p>
    </section>

    <section>
      <h2>8. Changes to Terms</h2>
      <p>We reserve the right to update or modify these terms and conditions at any time without prior notice. Continued use of the website after changes are made constitutes acceptance of the revised terms.</p>
    </section>

    <section>
      <h2>9. Governing Law</h2>
      <p>These terms and conditions are governed by and construed in accordance with the laws of India. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts in West Bengal.</p>
    </section>

    <section>
      <h2>10. Contact Information</h2>
      <p>If you have any questions, concerns, or feedback regarding these terms and conditions, please contact us at:</p>
      <p>Email: <a href="mailto:info@reliep.com">info@reliep.com</a></p>
      <p>Phone: 02269719082/9062000150</p>
      {/* <p>Address: 123, Business Street, Mumbai, India</p> */}
    </section>

    <footer>
      <p>These terms and conditions were last updated on <span id="lastUpdated">17-01-2025</span>.</p>
    </footer>
  </div>
</section>







 <Footer />



    </>
)
}
export default Terms;