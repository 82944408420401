import { Helmet } from "react-helmet";
import Footer from "../Components/Footer";
import Innernavbar from "../Components/Innernavbar";
import Navbar from "../Components/Navbar";

function RefundPolicy(){
    return(
        <>


<Helmet>
        <link rel="stylesheet" href="/assets/css/about.css" />
      </Helmet>

<Navbar />


<section class="container max-w-7xl  mx-auto py-16 px-4 flex">

<div className="container privacy">
<header>
<h1>Service Cancel or Refund Policy</h1>
</header>
  
<section>
  <h2>1. Overview</h2>
  <p>
    At Reliep, we are dedicated to providing exceptional services. If you need to cancel your service or request a refund, please review our policy outlined below.
  </p>
</section>
<section>
  <h2>2. Service Cancellation</h2>

  <h3>2.1. Canceling Your Service</h3>
  <p>
    You can cancel your service at any time. To cancel, please contact our customer service team at <a href="mailto:care@reliep.com">care@reliep.com</a> or call us at 9062000150. Be sure to provide your service ID and any relevant details in your request.
  </p>

  <h3>2.2. Cancellation Timing</h3>
  <p>
    If you cancel your service, it will remain active until the end of your current billing cycle. You will not be charged for subsequent billing cycles after cancellation.
  </p>
  </section>
  <section>
  <h2>3. Refund Policy</h2>

  <h3>3.1. Refund Eligibility</h3>
  <p>
    Refunds may be considered under the following conditions:
    <ul>
      <li><strong>Refund Within Initial Period</strong>: If you cancel your service within 7 days of the initial payment and have not accessed service benefits, you may be eligible for a full refund.</li>
      <li><strong>Service Issues</strong>: If you experience significant issues with the service that we cannot resolve, please contact us within 1 day. We will review your case and may offer a refund or other compensation as appropriate within 7 working days.</li>
    </ul>
  </p>

  <h3>3.2. Refund Process</h3>
  <p>
    To request a refund, please follow these steps:
    <ol>
      <li>Contact our customer service team at <a href="mailto:care@reliep.com">care@reliep.com</a> or 9062000150 with your refund request.</li>
      <li>Provide your service ID and any relevant details about your issue or reason for requesting a refund.</li>
    </ol>
  </p>

  <h3>3.3. Refund Timing</h3>
  <p>In the refund policy, processing will be 3 business days and credit will be received within 7-10 business days.</p>

  <h3>3.4. Non-Refundable Service Fees</h3>
  <p>
    Certain fees or services are non-refundable, including but not limited to:
    <ul>
      <li>Fees for already accessed benefits</li>
      <li>Customized or special services</li>
      <li>Services with a clear no-refund policy</li>
    </ul>
  </p>
  </section>
  <section>
  <h2>4. Contact Us</h2>
  <p>
    If you have any questions about our service cancellation or refund policy, please reach out to us at:
  </p>
  <ul>
    <li><strong>Email</strong>: <a href="mailto:care@reliep.com">care@reliep.com</a></li>
    <li><strong>Phone</strong>: 9062000150</li>
    <li><strong>Mailing Address</strong>: Tarakeswar muktarpur Hooghly West Bengal 712401</li>
  </ul>
  </section>
  <section>
  <h2>5. Changes to This Policy</h2>
  <p>
    We may update this policy periodically. Any changes will be posted on this page with an updated revision date.
  </p>
  </section>
  <p><strong>Effective Date</strong>: 01-08-2024</p>
</div>
</section>




 <Footer />



        </>
    )
}
export default RefundPolicy;